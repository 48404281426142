<template>
    <div class="uk-container">
        <MainForm/>
    </div>
</template>

<script>
    import MainForm from './components/MainForm.vue'
    import UIkit from 'uikit';
    import Icons from 'uikit/dist/js/uikit-icons';

    UIkit.use(Icons);


    export default {
        name: 'App',
        components: {
            MainForm
        }
    }
</script>

<style>
    @import "https://cdn.jsdelivr.net/npm/uikit@3.13.7/dist/css/uikit.min.css";
</style>
