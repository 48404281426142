
<template>
    <div style="z-index:0">

        <form v-show="loading==false" class="uk-form-stacked uk-width-1-1">
            <div class="uk-grid-small" uk-grid>
                <div class="uk-margin uk-width-1-1">
                    <label class="uk-form-label">Model Code</label>
                    <div class="uk-form-controls">
                        <div class="uk-inline uk-width-1-1">
                            <button class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" v-on:click="onModelCodeScanned"></button>
                            <input v-model="modelCode" class="uk-input uk-width-1-1" @change="onModelCodeChanged">
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="asset" v-show="saving==false" class="uk-grid-small" style="padding-bottom:100px" uk-grid>
                    <div class="uk-text-lead">New <b>{{asset.categoryName}}</b></div>
                    <div class="uk-margin uk-width-1-1">
                        <label class="uk-form-label" for="txtAssetId">Asset ID<span class="required">*</span></label>
                        <div class="uk-form-controls">
                            <div class="uk-inline uk-width-1-1">
                                <button class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" v-on:click="onAssetIdScanned"></button>
                                <input id="txtAssetId" v-model="assetId" class="uk-input uk-width-1-1" @change="onAssetIdChanged">
                            </div>
                        </div>
                    </div>
                    <div class="uk-margin uk-width-1-1">
                        <label class="uk-form-label">Title<span class="required">*</span></label>
                        <div class="uk-form-controls">
                            <div class="uk-width-1-1">
                                <input v-model="asset.title" class="uk-input uk-width-1-1">
                            </div>
                        </div>
                    </div>
                    <div v-for="field in asset.fields" class="uk-margin uk-width-1-1">
                        <label class="uk-form-label">{{field.name}}<span v-show="field.required==1" class="required">*</span></label>
                        <div class="uk-form-controls">
                            <div class="uk-width-1-1">
                                <div v-if="field.type=='text'" class="uk-form-controls">
                                    <div class="uk-inline uk-width-1-1">
                                        <button class="uk-form-icon uk-form-icon-flip" uk-icon="icon: camera" v-on:click="onCodeScanned(field)"></button>
                                        <input v-model="field.value" class="uk-input uk-width-1-1">
                                    </div>
                                </div>
                                <select v-if="field.type=='menu'" v-model="field.value" class="uk-input uk-width-1-1">
                                    <option v-for="option in field.choices.split(',')" :value="option">{{option}}</option>
                                </select>
                                <DatePicker v-if="field.type=='date'" v-model="field.value" show-weeknumbers></DatePicker>
                            </div>
                        </div>
                    </div>
                    <button class="uk-button uk-button-primary uk-button-large" :disabled="isFormValid==false" style="position:fixed; left:0; width:100%; bottom:0; z-index:900" v-on:click="onSave">Create asset</button>
                </div>
                <div v-show="saving==true" style="text-align:center">
                    <div uk-spinner="ratio: 3"></div>
                    <div>Saving...</div>
                </div>
            </div>
        </form>
        <div v-show="loading==true" style="text-align:center; padding-top:100px">
            <div uk-spinner="ratio: 3"></div>
            <div>Loading the catalog</div>
        </div>
        <StreamBarcodeReader ref="scanner"></StreamBarcodeReader>

    </div>
</template>

<script setup language="javascript">
    import { ref, onMounted, computed } from 'vue';
    import axios from 'axios';
    import UIkit from 'uikit';
    import StreamBarcodeReader from "./StreamBarcodeReader.vue";
    import { Calendar, DatePicker } from 'v-calendar';

    const serviceURL = process.env.VUE_APP_SERVICE_URL;
    const saving = ref(false);
    const loading = ref(false);
    const scanner = ref(null);
    const asset = ref(null);
    const assetId = ref("")
    const modelCode = ref("")
    var catalog = {};


    const isFormValid = computed(() => {
        var valid = true;
        valid = valid & assetId.value != "";
        valid = valid & asset.value.title != "";
        for (let index = 0; index < asset.value.fields.length; ++index) {
            const f = asset.value.fields[index];
            if (f.required == 1) {
                valid = valid & f.value != "";
            }
        };
        return valid;
    });

    const onAssetIdScanned = (ev) => {
        ev.preventDefault();
        scanner.value.Capture((txt) => {
            assetId.value = txt;
        });


    }
    const onModelCodeScanned = (ev) => {
        ev.preventDefault();
        scanner.value.Capture((txt) => {
            modelCode.value = txt;
            if (catalog.hasOwnProperty(modelCode.value)) {
                asset.value = catalog[modelCode.value];
            }
        });

    }

    const onCodeScanned = (field) => {
        const ev = event || window.event;
        ev.preventDefault();
        scanner.value.Capture((txt) => {
            field.value = txt;
        });

    }

    const onModelCodeChanged = (ev) => {
        ev.preventDefault();
        if (catalog.hasOwnProperty(modelCode.value)) {
            asset.value = catalog[modelCode.value];
        }
        
    }

    const onSave = (ev) => {
        ev.preventDefault();
        postAsset();

    }

    const getCatalog = async () => {
            loading.value = true;

            const headers = {
                'content-type': 'application/json',
                'Cache-Control': 'no-cache',
                'ocp-apim-subscription-key': 'c9b178b8cae84e0ea83d47443cc13c70'
            };

            axios.post(serviceURL + 'GetAssetCatalog', {}, {
                headers : {
                    'content-type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'ocp-apim-subscription-key': 'c9b178b8cae84e0ea83d47443cc13c70'
                }
            }).then((json) => {
                catalog = json.data;
                loading.value = false;
            }).catch((error) => {
                UIkit.notification(error.message, { status: 'danger', pos: 'bottom-center' })
                catalog = {};
                loading.value = false;
            });
    }


    const postAsset = async () => {
        if (modelCode.value) {
            saving.value = true;
            asset.value.assetId = assetId.value;
            //alert(JSON.stringify(asset.value));
            axios.post(serviceURL + 'CreateAssetInstance', asset.value, {
                headers: {
                    'content-type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'ocp-apim-subscription-key': 'c9b178b8cae84e0ea83d47443cc13c70'
                }
            }).then((json) => {
                assetId.value = "";
                UIkit.notification("Asset created!", { status: 'success', pos: 'bottom-center' })
                saving.value = false;
            }).catch((error) => {
                if (error.response.status == 400) {
                    UIkit.notification("Error : " + error.response.data, { status: 'danger', pos: 'bottom-center' })
                } else {
                    UIkit.notification("Error: " + error.message, { status: 'danger', pos: 'bottom-center' })
                }
                saving.value = false;
            });
        }
    }


    getCatalog();

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'v-calendar/dist/style.css';
    .required {
        padding-left:3px;
        color: red;
        vertical-align: super;
        font-size: smaller;
        line-height: normal;
    }

    .uk-button-danger:disabled, .uk-button-default:disabled, .uk-button-primary:disabled, .uk-button-secondary:disabled{
        background-color:white
    }

    .vc-container {
        border-radius: 0;
        border-color: #e5e5e5;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size:16px
    }
</style>