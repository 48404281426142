<template>
    <div v-show="isRunning" class="scanner-container" style="position:absolute;top:0;bottom:0;left:0;right:0; width:100%; height:100%;z-index:999">
        <div style="width:100%;height:100%">
            <video poster="data:image/gif,AAAA" ref="scanner"></video>
            <div class="overlay-element"></div>
            <select class="uk-select" style="z-index:1000; position:fixed; width:100%" @change="onCameraChange()">
                <option v-for="camera in cameras" :value="camera.deviceId">
                    {{camera.label}}
                </option>
            </select>
            <button class="uk-button" style="position:fixed; left:0; width:100%; bottom:0; z-index:1000"  v-on:click="cancel">Cancel</button>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { BrowserMultiFormatReader, Exception } from "@zxing/library";
    const scanner = ref(null);
    const selectedDeviceId = ref(null);
    const cameras = ref(null);
    const isRunning = ref(false);
    const callBack = ref(null);
    const codeReader = new BrowserMultiFormatReader();
    const isMediaStreamAPISupported = navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices;

    codeReader.listVideoInputDevices().then((videoInputDevices) => {
        cameras.value = videoInputDevices;
        selectedDeviceId.value = videoInputDevices[0].deviceId
    });

    const onCameraChange = (cameraId) => {
        selectedDeviceId.value = cameraId;
        initCamera();
    }

    const Capture = (callBackMethod) => {
        callBack.value = callBackMethod;
        initCamera();
    }

    const initCamera = () => {
        isRunning.value = true;
        codeReader.reset();
        codeReader.decodeFromVideoDevice(
            selectedDeviceId.value,
            scanner.value,
            (result, err) => {
                if (result) {
                    isRunning.value = false;
                    codeReader.reset();
                    callBack.value(result.text);
                }
            }
        );
    }

    const cancel = () => {
        isRunning.value = false;
        codeReader.reset();
    }

    defineExpose({ Capture });

</script>

<style scoped>
    video {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .scanner-container {
        position: relative;
    }

    .overlay-element {
        position: absolute;
        top: 0;
        width: 100%;
        height: 99%;
        background: rgba(30, 30, 30, 0.5);
        -webkit-clip-path: polygon( 0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0% );
        clip-path: polygon( 0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0% );
    }

  
</style>